import type { ENUMS } from 'vue-components2'
import type { RouteLocationRaw } from 'vue-router'

export enum PAGE_WRAPPER_EMIT_EVENTS {
    WINDOW_RESIZE = 'window-resize',
    ASIDE_TOGGLE = 'aside-toggle',
    ASIDE_INIT = 'aside-init'
}

/**
 * Represents the different types of headers for the PageWrapper component.
 *
 * @typedef {('landing-page-type' | 'user-admin-type')} HeaderType
 * @property {string} 'LANDING_PAGE' - The type for the landing page header.
 * @property {string} 'USER_ADMIN' - The type for the user admin header.
 */
type HeaderType = typeof ENUMS.HEADER_TYPES[keyof typeof ENUMS.HEADER_TYPES]

/**
 * Interface for the props of the PageWrapper component.
 *
 * @interface PageWrapperProps
 * @property {Array<any>} tabs - An array of tab items.
 * @property {string} title - The title of the page.
 * @property {boolean} isAdminProp - Indicates if the user is an admin.
 * @property {boolean} loading - Indicates if the loading state is active.
 * @property {boolean} containerEnabled - Enables the container.
 * @property {boolean} asideEnabled - Enables the aside.
 * @property {string} headerType - The type of the header. Replace with a union type based on your ENUMS if applicable.
 */
export interface PageWrapperProps {
    tabs?: any
    title?: string
    isAdminProp?: boolean
    loading?: boolean
    containerEnabled?: boolean
    asideEnabled?: boolean
    headerType?: HeaderType
    mainAttrs?: any
    containerAttrs?: any
}

export interface AsideItem {
    iconClassName: string
    label: string
    id: string
    activeRouteIndexArray: number[]
    children: AsideChildItem[]
    index: number
}

export interface AsideChildItem {
    index: number
    label: string
    parent_id: string
    routeName: string
}

export interface AsideInitialItem {
    iconClassName: string
    label: string
    to?: RouteLocationRaw
    children?: AsideInitialChildItem[]
    sort: number
}

export interface AsideInitialChildItem {
    label: string
    to?: RouteLocationRaw
    hidden?: boolean
}

export interface AsideHtmlReturn {
    /**
     * An array of aside items.
     * @type {AsideItem[]}
     */
    Items: AsideItem[]
    /**
     * Indicates whether the aside is currently opened.
     * @type {boolean}
     */
    opened: boolean
    /**
     * Indicates whether the animation has ended.
     * @type {boolean}
     */
    animationEnded: boolean
    /**
     * Indicates whether the animation has started.
     * @type {boolean}
     */
    animationStarted: boolean
    /**
     * Retrieves the initial state of the aside.
     * @returns {boolean} - The initial state indicating if the aside is opened.
     */
    GET_INITIAL_STATE: () => boolean
    /**
     * Toggles the open/close state of the aside.
     * @param {boolean} boolean - If true, opens the aside; if false, closes it.
     * @param {boolean} [cache=false] - If true, caches the state in session storage.
     */
    TOGGLE: (boolean: boolean, cache?: boolean) => void
}
