import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import moment from 'moment'
import mitt from 'mitt'
import { install } from 'vue3-recaptcha-v2'

import { AppToast } from 'vue-components'
import { FIRE_LOG_EVENT } from 'logging-module'
import {
    EVENTS_ENUMS,
    GET_CACHE_REQUEST_CONFIG,
    SET_AXIOS_REQUEST_INTERCEPTOR,
    SET_AXIOS_RESPONSE_INTERCEPTOR,
    U_GET_ERROR,
    U_GET_ROUTES,
    UserAgent,
    axios
} from 'platform-module'
import { ROLE_ID_ENUMS } from 'auth-module'

import { MOUNT_TOAST } from 'vue-components2'
import { PROVIDE_MODULES_DATA } from './provide-module-data'
import { LISTEN_BROWSER_EXTENSION_EVENTS } from '@/listen-browser-extension-events.js'
import { INIT_COMMON_COMPONENTS, INIT_FA_ICONS } from '@/components-registration.js'
import { SET_DIRECTIVES } from '@/set-directives.js'
import { INIT_MODULES_EVENT_LISTENERS } from '@/listen-modules-events'
import { createRouterFunction } from '@/router/index.js'
import { routes } from '@/router/routes.js'

import App from '@/AppV2.vue'
import mixin from '@/helpers/mixin.js'

import '~/styles/app.scss'
import 'vue-components/dist/style.css'
import 'icons-font-module/epicvpn/build/epicvpn.css'
import 'icons-font-module/common/build/common.css'
import vLoading from '@/v-loading.ts'

const app = createApp(App)
const routeNames = U_GET_ROUTES({ routes, key: 'name', ROLE_ID_ENUMS })
const routePaths = U_GET_ROUTES({ routes, key: 'path', ROLE_ID_ENUMS })

// SET VUE CONFIG PROPERTIES START
app.config.globalProperties.$emitter = mitt()
app.config.globalProperties.$moment = moment
app.config.globalProperties.$userAgent = new UserAgent()

app.provide('emitter', app.config.globalProperties.$emitter)
// SET VUE CONFIG PROPERTIES END

// SET GLOBAL INSTANCES START
window.routeNames = routeNames
window.routePaths = routePaths
window.toast = AppToast
// SET GLOBAL INSTANCES END

const pinia = createPinia()
const router = createRouterFunction({ routes, routeNames })

pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
    // TODO: remove
    store.router = markRaw(router)
    store.axios = markRaw(axios)
    store.toast = AppToast
})

app.use(pinia)
app.use(router)

app.use(install, {
    sitekey: '6LdXoi0qAAAAAAmJZPFuhZlYkwkjYTpcrxIzEoPN',
    cnDomains: false // Optional, If you use in China, set this value true
})

app.mixin(mixin)
app.provide('vm', app)
app.provide('router', router)

INIT_COMMON_COMPONENTS(app)
INIT_FA_ICONS(app)

SET_DIRECTIVES(app)
SET_AXIOS_REQUEST_INTERCEPTOR({ requestConfig: GET_CACHE_REQUEST_CONFIG({ envString: import.meta.env.VITE_CACHE_REQUEST_CONFIG }) })
SET_AXIOS_RESPONSE_INTERCEPTOR({ router, errorCallback: (error) => {
    if (error.code !== 'ERR_CANCELED') {
        MOUNT_TOAST({
            text: U_GET_ERROR(error),
            type: 'danger',
            size: 'l',
            delay: 5000
        })
    }
} })

// GLOBAL ERRORS HANDLER START
app.config.errorHandler = (error) => {
    error?.name !== 'AxiosError' && FIRE_LOG_EVENT({ eventName: EVENTS_ENUMS.LOG.ERROR_CONSOLE_LOG, payload: error })
}
// GLOBAL ERRORS HANDLER END

LISTEN_BROWSER_EXTENSION_EVENTS({ router, routeNames })
INIT_MODULES_EVENT_LISTENERS()
PROVIDE_MODULES_DATA(app)

app.directive('loading', vLoading)

app.mount('#app')
