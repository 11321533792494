import { U_HAS_ACCESS_TO_ROUTE } from 'platform-module'
import { ROLE_ID_ENUMS } from 'auth-module'
import { SUB_ACTIONS_ENUMS } from 'billing-module/src/static'
import { useAppStore, useAuthStore, useNewAccountBillingStore } from '@/stores'

const modules = import.meta.glob('../views/**/*.vue')
const page = path => modules[`../views/${path}`]

const userMeta = {
    guards: [ROLE_ID_ENUMS.USER],
    onlyVerified: true,
    onlyAuth: true,
    onlyNotDeactivated: true,
    accessCheck: true,
    beforeFirstPageLoadActions: ['getCurrentSub']
}

const adminMeta = {
    guards: [ROLE_ID_ENUMS.ADMIN],
    onlyVerified: true,
    onlyAuth: true,
    onlyNotDeactivated: true,
    accessCheck: true,
    beforeFirstPageLoadActions: []
}

const adminAndAuthorMeta = {
    ...adminMeta,
    guards: [ROLE_ID_ENUMS.ADMIN, ROLE_ID_ENUMS.AUTHOR]
}

const publicMeta = {
    guards: [ROLE_ID_ENUMS.PUBLIC],
    accessCheck: false,
    beforeFirstPageLoadActions: []
}

// onlyNotLogged: true - if user logged no matter verified or not, and go to public page for example /password/forgot, then redirect

const routes = [
    {
        path: '/r/:id',
        name: 'referral',
        meta: { ...publicMeta },
        component: page('Home.vue')
    },
    {
        path: '/',
        name: 'main',
        meta: { ...publicMeta },
        redirect: window.configs.locale
    },
    {
        path: '/:lang',
        name: 'home',
        meta: { ...publicMeta, beforeFirstPageLoadActions: ['getCurrentSub'] },
        component: page('Home.vue')
    },
    {
        path: '/:lang/signup',
        name: 'signup',
        meta: {
            ...publicMeta,
            onlyNotLogged: true,
            getRedirectPath: ({ lang }) => `/${lang}/`
        },
        component: page('Signup.vue')
    },
    {
        path: '/:lang/signin',
        name: 'signin',
        meta: {
            ...publicMeta,
            onlyNotLogged: true,
            getRedirectPath: ({ lang }) => `/${lang}/`
        },
        component: page('Signin.vue')
    },
    {
        path: '/:lang/password/forgot',
        name: 'password.forgot',
        meta: { ...publicMeta, onlyNotLogged: true },
        component: page('Password/ViewForgot.vue')
    },
    {
        path: '/:lang/password/forgot/verify',
        name: 'password.verify',
        meta: {
            ...publicMeta,
            exclude: true,
            accessCheck: true,
            getRedirectPath: ({ lang, user, chExists, chDecoded }) =>
                user || (!user && chExists && !chDecoded)
                    ? `/${lang}/`
                    : `/${lang}/password/forgot`,
            challengeRules: ({ user, chExists }) => {
                const authStore = useAuthStore()

                return {
                    emailCheck: !!user,
                    tokenCheck: chExists,
                    validCheck: chExists,
                    existanceCheck: !authStore.verification_login,
                    forceLogout: chExists && !!authStore.user && !authStore.user.delete_at
                }
            }
        },
        component: page('Password/ViewVerify.vue')
    },
    {
        path: '/:lang/email/verify',
        name: 'email.verify',
        meta: {
            guards: [ROLE_ID_ENUMS.USER, ROLE_ID_ENUMS.ADMIN, ROLE_ID_ENUMS.AUTHOR],
            accessCheck: true,
            exclude: true, // if true, then this route willn't be added to accessPages on first initialization
            challengeRules: ({ user, chExists }) => {
                // emailCheck: true - only if user LOGGED (checking if email of LOGGED user same as email from chDecoded chanllenge value)
                // tokenCheck: true - only if chExists true (checking if chDecoded challenge has token)
                // validCheck: true - only if url has challenge query parameter (checking if challenge value is valid)
                // existanceCheck: true - only if user NOT LOGGED (checking if url has challenge query parameter )
                return {
                    emailCheck: !!user,
                    tokenCheck: chExists,
                    validCheck: chExists,
                    existanceCheck: !user
                }
            },
            getRedirectPath: ({ lang }) => `/${lang}/`
        },
        component: page('Email/ViewVerify.vue')
    },
    {
        path: '/:lang/account/email/change/verify',
        name: 'account.email.change.verify',
        meta: {
            guards: [ROLE_ID_ENUMS.USER],
            accessCheck: true,
            exclude: false,
            onlyAuth: true,
            onlyVerified: true,
            onlyNotDeactivated: true,
            challengeRules: ({ user, chExists }) => {
                // isTempEmail: true, means need compare decoded challenge email with user's "email_tmp", not with "email"
                return {
                    emailCheck: !!user,
                    tokenCheck: chExists,
                    validCheck: chExists,
                    existanceCheck: true,
                    isTempEmail: true
                }
            },
            getRedirectPath: ({ lang }) => `/${lang}/`
        },
        component: page('Email/ViewNewEmailVerify.vue')
    },
    {
        path: '/:lang/admin/account/email/change/verify',
        name: 'admin.account.email.change.verify',
        meta: {
            guards: [ROLE_ID_ENUMS.ADMIN, ROLE_ID_ENUMS.AUTHOR],
            accessCheck: true,
            exclude: false,
            onlyAuth: true,
            onlyVerified: true,
            onlyNotDeactivated: true,
            challengeRules: ({ user, chExists }) => {
                // isTempEmail: true, means need compare decoded challenge email with user's "email_tmp", not with "email"
                return {
                    emailCheck: !!user,
                    tokenCheck: chExists,
                    validCheck: chExists,
                    existanceCheck: true,
                    isTempEmail: true
                }
            },
            getRedirectPath: ({ lang }) => `/${lang}/`
        },
        component: page('Email/ViewNewEmailVerify.vue')
    },
    {
        path: '/:lang/password/reset',
        name: 'password.reset',
        meta: {
            ...publicMeta,
            onlyNotLogged: true,
            accessCheck: true,
            getRedirectPath: ({ lang, user }) => (user
                ? `/${lang}/`
                : `/${lang}/password/forgot`)
        },
        component: page('Password/ViewReset.vue')
    },
    {
        path: '/:lang/order-v2',
        name: 'order2',
        meta: {
            guards: [ROLE_ID_ENUMS.USER, ROLE_ID_ENUMS.PUBLIC],
            accessCheck: false,
            onlyNotDeactivated: true
        },
        component: page('Order2.vue')
    },
    {
        path: '/:lang/order-received/thanks',
        name: 'order-received.thanks',
        meta: {
            guards: [ROLE_ID_ENUMS.USER],
            accessCheck: true,
            exclude: true
        },
        component: page('Thankyou.vue')
    },
    {
        path: '/:lang/reactivate',
        name: 'reactivate-account',
        meta: {
            guards: [ROLE_ID_ENUMS.USER, ROLE_ID_ENUMS.ADMIN, ROLE_ID_ENUMS.AUTHOR],
            onlyVerified: false,
            accessCheck: true,
            exclude: true,
            onlyAuth: false
        },
        getRedirectPath: ({ lang }) => `/${lang}/`,
        component: page('ViewReactivateAccount.vue')
    },

    // user pages start
    {
        path: '/:lang/dashboard',
        name: 'dashboard',
        meta: { ...userMeta },
        component: page('User/Dashboard.vue')
    },
    {
        path: '/:lang/account',
        name: 'account',
        component: page('Account/ViewAccount.vue'),
        redirect: { name: 'account.profile' },
        meta: { ...userMeta },
        children: [
            {
                path: '/:lang/account/profile',
                name: 'account.profile',
                component: page('Account/ViewAccountProfile.vue'),
                meta: { ...userMeta }
            },
            {
                path: '/:lang/account/security',
                name: 'account.security',
                component: page('Account/ViewAccountSecurity.vue'),
                meta: { ...userMeta }
            },
            {
                path: '/:lang/account/notifications',
                name: 'account.notifications',
                component: page('Account/ViewAccountNotifications.vue'),
                meta: { ...userMeta }
            }
        ]
    },
    {
        path: '/:lang/billing',
        name: 'billing',
        component: page('User/Billing/Billing.vue'),
        redirect: { name: 'billing.membership2' },
        meta: { ...userMeta },
        children: [
            {
                path: '/:lang/billing/membership-v2',
                name: 'billing.membership2',
                component: page('User/Billing/BillingMembershipV2.vue'),
                meta: { ...userMeta },
                beforeEnter: (to, from, next) => {
                    const appStore = useAppStore()
                    const { currentSub } = useNewAccountBillingStore()
                    U_HAS_ACCESS_TO_ROUTE({ sub: { ...currentSub, exists: !!currentSub }, key: 'route-membership' })
                        ? next()
                        : next(`/${appStore.locale}/billing/order-v2`)
                }
            },
            {
                path: '/:lang/billing/upgrade-v2',
                name: 'billing.upgrade2',
                meta: {
                    guards: [ROLE_ID_ENUMS.USER],
                    onlyVerified: true,
                    onlyAuth: true,
                    onlyNotDeactivated: true
                },
                component: page('User/Billing/BillingUpgradeV2.vue'),
                beforeEnter: (to, from, next) => {
                    const appStore = useAppStore()
                    const { currentSub, IsLastInvoiceFinalizing } = useNewAccountBillingStore()
                    U_HAS_ACCESS_TO_ROUTE({
                        sub: { ...currentSub, exists: !!currentSub },
                        key: 'route-upgrade',
                        hasFinalizingInvoice: IsLastInvoiceFinalizing
                    })
                        ? next()
                        : next(`/${appStore.locale}/page-not-found`)
                }
            },
            {
                path: '/:lang/billing/reactivate-v2',
                name: 'billing.reactivate2',
                meta: {
                    guards: [ROLE_ID_ENUMS.USER],
                    onlyVerified: true,
                    onlyAuth: true,
                    onlyNotDeactivated: true
                },
                component: page('User/Billing/BillingOrder2.vue'),
                beforeEnter: (to, from, next) => {
                    const appStore = useAppStore()
                    const { currentSub } = useNewAccountBillingStore()
                    U_HAS_ACCESS_TO_ROUTE({ sub: { ...currentSub, exists: !!currentSub }, key: 'route-reactivate' })
                        ? next()
                        : next(`/${appStore.locale}/page-not-found`)
                },
                props: {
                    getOldProducts: true
                }
            },
            {
                path: '/:lang/billing/bonus-upgrade',
                name: 'billing.bonus-upgrade',
                meta: {
                    guards: [ROLE_ID_ENUMS.USER],
                    onlyVerified: true,
                    onlyAuth: true,
                    onlyNotDeactivated: true
                },
                component: page('User/Billing/BillingOrder2.vue'),
                beforeEnter: async (to, from, next) => {
                    const appStore = useAppStore()
                    const { currentSub } = useNewAccountBillingStore()
                    U_HAS_ACCESS_TO_ROUTE({ sub: { ...currentSub, exists: !!currentSub }, key: 'route-bonus-upgrade' })
                        ? next()
                        : next(`/${appStore.locale}/page-not-found`)
                },
                props: {
                    action: SUB_ACTIONS_ENUMS.BONUS_UPGRADE_SUBSCRIPTION
                }
            },
            {
                path: '/:lang/billing/fix-payment-method',
                name: 'billing.fix-payment-method',
                meta: { ...userMeta },
                component: page('User/Billing/BillingFixPaymentMethod.vue')
            },
            {
                path: '/:lang/billing/order-v2',
                name: 'billing.order2',
                component: page('User/Billing/BillingOrder2.vue'),
                meta: { ...userMeta },
                beforeEnter: (to, from, next) => {
                    const appStore = useAppStore()
                    const { currentSub } = useNewAccountBillingStore()
                    currentSub
                        ? next(`/${appStore.locale}/billing/membership`)
                        : next()
                }
            },
            {
                path: '/:lang/billing/payment-methods',
                name: 'billing.payment-methods',
                component: page('User/Billing/BillingPaymentMethodsV2.vue'),
                meta: { ...userMeta }
            },
            {
                path: '/:lang/billing/invoices',
                name: 'billing.invoices',
                component: page('User/Billing/BillingInvoicesV2.vue'),
                meta: { ...userMeta }
            }
        ]
    },
    {
        path: '/:lang/features/family-sharing',
        name: 'features.family-sharing',
        component: page('User/FamilySharing.vue'),
        meta: { ...userMeta }
    },
    {
        path: '/:lang/familysharing/invite/verify',
        name: 'familysharing.invite',
        component: page('FamilySharingVerify.vue'),
        meta: {
            ...publicMeta,
            challengeRules: ({ user, chExists }) => {
                return {
                    emailCheck: !!user,
                    validCheck: chExists,
                    existanceCheck: true
                }
            },
            getRedirectPath: ({ lang }) => `/${lang}/`
        }
    },
    // user pages end

    // admin pages start
    {
        path: '/:lang/admin',
        name: 'admin',
        meta: { ...adminAndAuthorMeta },
        beforeEnter: (to, from, next) => {
            const appStore = useAppStore()
            next(`/${appStore.locale}/admin/dashboard`)
        },
        component: page('Admin/Dashboard.vue')
    },
    {
        path: '/:lang/admin/dashboard',
        name: 'admin.dashboard',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Dashboard.vue')
    },
    {
        path: '/:lang/admin/customers',
        name: 'admin.customers',
        meta: { ...adminMeta },
        component: page('Admin/Customers.vue')
    },
    {
        path: '/:lang/admin/customers/:id',
        name: 'admin.customers.customer',
        meta: { ...adminMeta },
        component: page('Admin/Customers/Customer.vue')
    },
    {
        path: '/:lang/admin/account',
        name: 'admin.account',
        component: page('Account/ViewAccount.vue'),
        redirect: { name: 'admin.account.profile' },
        meta: { ...adminAndAuthorMeta },
        props: {
            isAdmin: true
        },
        children: [
            {
                path: '/:lang/admin/account/profile',
                name: 'admin.account.profile',
                component: page('Account/ViewAccountProfile.vue'),
                meta: { ...adminAndAuthorMeta }
            },
            {
                path: '/:lang/admin/account/security',
                name: 'admin.account.security',
                component: page('Account/ViewAccountSecurity.vue'),
                meta: { ...adminAndAuthorMeta }
            },
            {
                path: '/:lang/admin/account/notifications',
                name: 'admin.account.notifications',
                component: page('Account/ViewAccountNotifications.vue'),
                meta: { ...adminAndAuthorMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/dashboard/invoices/export-pdf',
        name: 'admin.dashboard.download.invoice',
        component: page('Admin/InvoiceDownload.vue'),
        meta: { ...adminAndAuthorMeta },
        children: []
    },

    // ADMIN STATISTICS START
    {
        path: '/:lang/admin/statistics/earnings',
        name: 'admin.statistics.earnings',
        meta: { ...adminMeta },
        component: page('Admin/Statistics/Earnings/Earnings.vue'),
        redirect: { name: 'admin.statistics.earnings.overview' },
        children: [
            {
                path: '/:lang/admin/statistics/earnings/overview',
                name: 'admin.statistics.earnings.overview',
                component: page('Admin/Statistics/Earnings/EarningsOverview.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/statistics/subscriptions',
        name: 'admin.statistics.subscriptions',
        meta: { ...adminMeta },
        component: page('Admin/Statistics/Subscriptions/Subscriptions.vue'),
        redirect: { name: 'admin.statistics.subscriptions.overview' },
        children: [
            {
                path: '/:lang/admin/statistics/subscriptions/overview',
                name: 'admin.statistics.subscriptions.overview',
                component: page('Admin/Statistics/Subscriptions/SubscriptionsOverview.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    // ADMIN STATISTICS END

    // ADMIN BLOG START
    {
        path: '/:lang/admin/blog/posts',
        name: 'admin.blog.posts',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Blog/Posts/Posts.vue'),
        redirect: { name: 'admin.blog.posts.overview' },
        children: [
            {
                path: '/:lang/admin/blog/posts/overview',
                name: 'admin.blog.posts.overview',
                component: page('Admin/Blog/Posts/PostsPublished.vue'),
                meta: { ...adminAndAuthorMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/blog/posts/create',
        name: 'admin.blog.posts.create',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Blog/Posts/PostsCreate.vue'),
        props: { module: 'blog' }
    },
    {
        path: '/:lang/admin/blog/posts/edit/:id',
        name: 'admin.blog.posts.edit',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Blog/Posts/PostsCreate.vue'),
        props: { module: 'blog' }
    },
    {
        path: '/:lang/admin/blog/categories',
        name: 'admin.blog.categories',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Blog/Categories/Categories.vue'),
        redirect: { name: 'admin.blog.categories.overview' },
        children: [
            {
                path: '/:lang/admin/blog/categories/overview',
                name: 'admin.blog.categories.overview',
                component: page('Admin/Blog/Categories/CategoriesOverview.vue'),
                meta: { ...adminAndAuthorMeta }
            }
        ]
    },
    // ADMIN BLOG END

    // ADMIN HELP CENTER START
    {
        path: '/:lang/admin/help-center/articles/create',
        name: 'admin.help-center.articles.create',
        component: page('Admin/Blog/Posts/PostsCreate.vue'),
        meta: { ...adminAndAuthorMeta },
        props: { module: 'help-center' }
    },
    {
        path: '/:lang/admin/help-center/articles/edit/:id',
        name: 'admin.help-center.articles.edit',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Blog/Posts/PostsCreate.vue'),
        props: { module: 'help-center' }
    },
    {
        path: '/:lang/admin/help-center/articles',
        name: 'admin.help-center.articles',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Help/Articles/Articles.vue'),
        redirect: { name: 'admin.help-center.articles.overview' },
        children: [
            {
                path: '/:lang/admin/help-center/articles/published',
                name: 'admin.help-center.articles.overview',
                component: page('Admin/Help/Articles/ArticlesPublished.vue'),
                meta: { ...adminAndAuthorMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/help-center/categories',
        name: 'admin.help-center.categories',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Help/Categories/Categories.vue'),
        redirect: { name: 'admin.help-center.categories.overview' },
        children: [
            {
                path: '/:lang/admin/help-center/categories/overview',
                name: 'admin.help-center.categories.overview',
                component: page('Admin/Help/Categories/CategoriesOverview.vue'),
                meta: { ...adminAndAuthorMeta }
            },
            {
                path: '/:lang/admin/help-center/categories/sub-categories',
                name: 'admin.help-center.categories.sub-categories',
                component: page('Admin/Help/Categories/CategoriesSubCategories.vue'),
                meta: { ...adminAndAuthorMeta }
            },
            {
                path: '/:lang/admin/help-center/categories/sorting',
                name: 'admin.help-center.categories.sorting',
                component: page('Admin/Help/Categories/CategoriesSorting.vue'),
                meta: { ...adminAndAuthorMeta }
            }
        ]
    },
    // ADMIN HELP CENTER END

    // ADMIN PAGES START
    {
        path: '/:lang/admin/pages/overview',
        name: 'admin.pages.overview',
        component: page('Admin/Pages/PagesOverview.vue'),
        meta: { ...adminAndAuthorMeta }
    },
    // ADMIN PAGES END

    // ADMIN STATIC PAGES START
    {
        path: '/:lang/admin/static-pages/overview',
        name: 'admin.staticPages.overview',
        component: page('Admin/StaticPages/StaticPagesOverview.vue'),
        meta: { ...adminAndAuthorMeta }
    },
    // ADMIN STATIC PAGES END

    // ADMIN OAUTH START
    {
        path: '/:lang/admin/oauth/server',
        name: 'admin.oauth.clients',
        component: page('Admin/OAuth/OAuthClientsList.vue'),
        meta: { ...adminAndAuthorMeta }
    },

    {
        path: '/:lang/admin/oauth/client',
        name: 'admin.oauth.servers',
        component: page('Admin/OAuth/OAuthProvidersList.vue'),
        meta: { ...adminAndAuthorMeta }
    },
    // ADMIN OAUTH END

    // ADMIN FAQ START
    {
        path: '/:lang/admin/faq/pages/create',
        name: 'admin.faq.pages.create',
        component: page('Admin/Faq/Pages/PageCreate.vue'),
        meta: { ...adminAndAuthorMeta },
        props: { module: 'faq' }
    },
    {
        path: '/:lang/admin/faq/list',
        name: 'admin.faq.list',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Faq/List/List.vue'),
        redirect: { name: 'admin.faq.list.overview' },
        children: [
            {
                path: '/:lang/admin/faq/list/overview',
                name: 'admin.faq.list.overview',
                component: page('Admin/Faq/List/ListOverview.vue'),
                meta: { ...adminAndAuthorMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/faq/list/edit/:id',
        name: 'admin.faq.list.edit',
        meta: { ...adminAndAuthorMeta },
        component: page('Admin/Faq/List/ListCreate.vue'),
        props: { module: 'faq' }
    },
    {
        path: '/:lang/admin/faq/list/create',
        name: 'admin.faq.list.create',
        component: page('Admin/Faq/List/ListCreate.vue'),
        meta: { ...adminAndAuthorMeta },
        props: { module: 'faq' }
    },
    // ADMIN FAQ CENTER END

    // ADMIN SEO START
    {
        path: '/:lang/admin/seo/main-pages',
        name: 'admin.seo.main-pages',
        component: page('Admin/Seo/SeoMainPages.vue'),
        meta: { ...adminMeta }
    },
    {
        path: '/:lang/admin/seo/categories',
        name: 'admin.seo.categories',
        component: page('Admin/Seo/SeoCategories.vue'),
        meta: { ...adminMeta }
    },
    {
        path: '/:lang/admin/seo/posts',
        name: 'admin.seo.posts',
        component: page('Admin/Seo/SeoPosts.vue'),
        meta: { ...adminMeta }
    },
    {
        path: '/:lang/admin/seo/articles',
        name: 'admin.seo.articles',
        component: page('Admin/Seo/SeoArticles.vue'),
        meta: { ...adminMeta }
    },
    // ADMIN SEO CENTER END

    // ADMIN PRODUCTS START
    {
        path: '/:lang/admin/payment-gateways/stripe',
        name: 'admin.payment-gateways.stripe',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Stripe/Stripe.vue'),
        redirect: { name: 'admin.payment-gateways.stripe.products' },
        children: [
            {
                path: '/:lang/admin/payment-gateways/stripe/products',
                name: 'admin.payment-gateways.stripe.products',
                component: page('Admin/Settings/Stripe/StripeProducts.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/payment-gateways/stripe/products/:product_id',
        name: 'admin.payment-gateways.stripe.products.product',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Products/Product.vue')
    },
    {
        path: '/:lang/admin/payment-gateways/paypal',
        name: 'admin.payment-gateways.paypal',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Paypal/Paypal.vue'),
        redirect: { name: 'admin.payment-gateways.paypal.products' },
        children: [
            {
                path: '/:lang/admin/payment-gateways/paypal/products',
                name: 'admin.payment-gateways.paypal.products',
                component: page('Admin/Settings/Paypal/PaypalProducts.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/payment-gateways/paypal/products/:product_id',
        name: 'admin.payment-gateways.paypal.products.product',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Products/Product.vue')
    },
    {
        path: '/:lang/admin/payment-gateways/iap',
        name: 'admin.payment-gateways.iap',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Iap/Iap.vue'),
        redirect: { name: 'admin.payment-gateways.iap.products' },
        children: [
            {
                path: '/:lang/admin/payment-gateways/iap/products',
                name: 'admin.payment-gateways.iap.products',
                component: page('Admin/Settings/Iap/IapProducts.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/payment-gateways/iap/products/:product_id',
        name: 'admin.payment-gateways.iap.products.product',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Products/Product.vue')
    },
    // CRYPTOMUS PRODUCTS START
    {
        path: '/:lang/admin/payment-gateways/cryptomus',
        name: 'admin.payment-gateways.cryptomus',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Cryptomus/Cryptomus.vue'),
        redirect: { name: 'admin.payment-gateways.cryptomus.products' },
        children: [
            {
                path: '/:lang/admin/payment-gateways/cryptomus/products',
                name: 'admin.payment-gateways.cryptomus.products',
                component: page('Admin/Settings/Cryptomus/CryptomusProducts.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/payment-gateways/cryptomus/products/:product_id',
        name: 'admin.payment-gateways.cryptomus.products.product',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Products/Product.vue')
    },
    {
        path: '/:lang/admin/payment-gateways/addons',
        name: 'admin.payment-gateways.addons',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Addons/Addons.vue'),
        redirect: { name: 'admin.payment-gateways.addons.products' },
        children: [
            {
                path: '/:lang/admin/payment-gateways/addons/modules',
                name: 'admin.payment-gateways.addons.modules',
                component: page('Admin/Settings/Addons/AddonsModules.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/payment-gateways/addons/products',
                name: 'admin.payment-gateways.addons.products',
                component: page('Admin/Settings/Addons/AddonsProducts.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/payment-gateways/addons/products/:product_id',
        name: 'admin.payment-gateways.addons.products.product',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Products/Product.vue')
    },
    // CRYPTOMUS PRODUCTS END
    // ADMIN PRODUCTS END

    // COUPONS START
    {
        path: '/:lang/admin/coupons',
        name: 'admin.coupons',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Coupons/Coupons.vue'),
        redirect: { name: 'admin.coupons.overview' },
        children: [
            {
                path: '/:lang/admin/coupons/overview',
                name: 'admin.coupons.overview',
                component: page('Admin/Settings/Coupons/CouponsOverview.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/coupons/preapplied',
                name: 'admin.coupons.preapplied',
                component: page('Admin/Settings/Coupons/CouponsPreapplied.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/coupons/:coupon_id',
        name: 'admin.coupons.coupon',
        meta: { ...adminMeta },
        component: page('Admin/Settings/Coupons/Coupon.vue')
    },
    // COUPONS END

    // NOTIFICATIONS START
    {
        path: '/:lang/admin/notifications',
        name: 'admin.notifications',
        meta: { ...adminMeta },
        component: page('Admin/Notifications/Notifications.vue'),
        redirect: { name: 'admin.notifications.email-lists' },
        children: [
            {
                path: '/:lang/admin/notifications/email-lists',
                name: 'admin.notifications.email-lists',
                component: page('Admin/Notifications/NotificationsEmailLists.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    // NOTIFICATIONS END

    // SERVERS START
    {
        path: '/:lang/admin/servers',
        name: 'admin.servers',
        meta: { ...adminMeta },
        redirect: { name: 'admin.servers.countries' }
    },
    {
        path: '/:lang/admin/servers/all-servers',
        name: 'admin.servers.all-servers',
        meta: { ...adminMeta },
        component: page('Admin/Servers/AllServers.vue')
    },
    {
        path: '/:lang/admin/servers/servers-list',
        name: 'admin.servers.servers-list',
        meta: { ...adminMeta },
        component: page('Admin/Servers/ServersList.vue')
    },
    {
        path: '/:lang/admin/servers/countries',
        name: 'admin.servers.countries',
        meta: { ...adminMeta },
        component: page('Admin/Servers/Countries/Countries.vue')
    },
    {
        path: '/:lang/admin/servers/countries/:country_code',
        name: 'admin.servers.countries.country',
        meta: { ...adminMeta },
        component: page('Admin/Servers/Countries/Country.vue')
    },
    // SERVERS END

    // LOGS START
    {
        path: '/:lang/admin/logs',
        name: 'admin.logs',
        meta: { ...adminMeta },
        component: page('Admin/Logs/Logs.vue'),
        redirect: { name: 'admin.logs.overview' },
        children: [
            {
                path: '/:lang/admin/logs/overview',
                name: 'admin.logs.overview',
                component: page('Admin/Logs/LogsOverview.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    {
        path: '/:lang/admin/logs/:id',
        name: 'admin.logs.log',
        meta: { ...adminMeta },
        component: page('Admin/Logs/Log.vue')
    },
    // LOGS END

    // PLANS CONSTRUCTOR START
    {
        path: '/:lang/admin/order/manage-plans',
        name: 'admin.order.manage-plans',
        meta: { ...adminMeta },
        component: page('Admin/Order/Order.vue'),
        redirect: { name: 'admin.order.manage-plans.website' },
        children: [
            {
                path: '/:lang/admin/order/manage-plans/stripe',
                name: 'admin.order.manage-plans.stripe',
                component: page('Admin/Order/OrderPlansBuilderStripe.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/order/manage-plans/paypal',
                name: 'admin.order.manage-plans.paypal',
                component: page('Admin/Order/OrderPlansBuilderPaypal.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/order/manage-plans/apple',
                name: 'admin.order.manage-plans.apple',
                component: page('Admin/Order/OrderPlansBuilderApple.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/order/manage-plans/cryptomus',
                name: 'admin.order.manage-plans.cryptomus',
                component: page('Admin/Order/OrderPlansBuilderCryptomus.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/order/manage-plans/addons',
                name: 'admin.order.manage-plans.addons',
                component: page('Admin/Order/OrderPlansBuilderAddons.vue'),
                meta: { ...adminMeta }
            },
            {
                path: '/:lang/admin/order/manage-plans/merged-addons',
                name: 'admin.order.manage-plans.merged-addons',
                component: page('Admin/Order/OrderPlansBuilderMergedAddons.vue'),
                meta: { ...adminMeta }
            }
        ]
    },
    // PLANS CONSTRUCTOR END
    // admin pages end

    {
        path: '/:lang/page-not-found',
        name: 'Error404',
        meta: { guards: [ROLE_ID_ENUMS.USER, ROLE_ID_ENUMS.ADMIN, ROLE_ID_ENUMS.PUBLIC, ROLE_ID_ENUMS.AUTHOR] },
        component: page('Error404.vue')
    }
]

export { routes }
