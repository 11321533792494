import { INIT_APP_EVENTS } from 'app-module'
import { INIT_RP_EVENTS } from 'referral-program-module'
import { INIT_USERS_EVENTS } from 'users-module'
import { INIT_BILLING_EVENTS } from 'billing-module'
import { INIT_LOG_EVENTS } from 'logging-module'
import { INIT_AUTH_EVENTS } from 'auth-module'
import { INIT_OAUTH_CLIENT_EVENTS } from 'oauth-client-module'
import { EVENTS_ENUMS, SET_CUSTOM_EVENT } from 'platform-module'
import { MOUNT_TOAST } from 'vue-components2'
import type { Component, VNode } from 'vue'
import type { NewToastComponentProps } from 'ts-types'

import { MODULES_BOOLEANS } from '@/static'

export function INIT_MODULES_EVENT_LISTENERS() {
    const { HAS_BILLING, HAS_REFERRAL, HAS_USERS, HAS_LOGGING, HAS_AUTH } = MODULES_BOOLEANS

    console.log(MODULES_BOOLEANS)

    HAS_REFERRAL && INIT_RP_EVENTS()
    HAS_BILLING && INIT_BILLING_EVENTS()
    HAS_USERS && INIT_USERS_EVENTS()
    HAS_LOGGING && INIT_LOG_EVENTS()
    HAS_AUTH && INIT_AUTH_EVENTS()
    INIT_OAUTH_CLIENT_EVENTS()
    INIT_APP_EVENTS()

    SET_CUSTOM_EVENT({
        eventName: EVENTS_ENUMS.PROJECT.SHOW_TOAST,
        callback: (
            { payload: { props, slotContent } }:
            { payload: { props: NewToastComponentProps, slotContent?: string | VNode | Component } }
        ): void => {
            MOUNT_TOAST(props, slotContent)
        }
    })
}
