import { DEBUG_ERROR, DEBUG_LOG } from 'platform-module'
import { useAuthUser } from 'auth-module'
import { useAuthStore } from '@/stores'
import { useNewAccountBillingStore } from '@/stores/account/AccountNewBillingStore'
import { MODULES_BOOLEANS } from '@/static'

export function doRequestsBeforeLoad({ to }) {
    const { GET_CURRENT_SUB } = useNewAccountBillingStore()
    const { AUTH_CHECK } = useAuthStore()

    return new Promise((resolve) => {
        const promises = []

        window.reload = false
        AUTH_CHECK()
            .then(async ({ user: newUser }) => {
                DEBUG_LOG('AUTH_CHECK user', newUser)
                const bpfpa = to.meta.beforeFirstPageLoadActions || []
                const { isUser, isVerified, isDeactivated } = useAuthUser({ user: newUser })

                if (bpfpa && bpfpa.includes('getCurrentSub') && isUser.value && isVerified.value && !isDeactivated.value) {
                    if (['billing.membership2', 'billing.upgrade2', 'billing.reactivate2', 'billing.bonus-upgrade', 'billing.order2'].includes(to.name)) {
                        // if current page is the page from the list, then get access to page only after request done
                        promises.push(GET_CURRENT_SUB.fn({ modulesBooleans: MODULES_BOOLEANS }))
                    }
                    else {
                        GET_CURRENT_SUB.fn({ modulesBooleans: MODULES_BOOLEANS })
                    }
                }

                promises.length
                    ? Promise.all(promises).then(() => resolve({
                            promises,
                            user: newUser,
                            success: true
                        }))
                    : resolve({
                            promises,
                            user: newUser,
                            success: true
                        })
            })
            .catch((error) => {
                DEBUG_ERROR('%cGET USER ERROR', 'color: red', error)
                resolve({
                    promises,
                    success: false,
                    error
                })
            })
    })
}
