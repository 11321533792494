<template>
    <RouterView />
    <LoadingOverlay
        background-color="#fff" color="#414141"
        :opened="appStore.isLogoutOverlayOpened"
        text="You're being logged out..."
    />
    <LineGradientLoadingBar :loading="appStore.redirectOverlay" />
    <Vpc v-if="vpcEnabled && mode === 'development'" />
    <ModalConfirmation ref="modalConfirmation" />
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { ROLE_NAME, U_GET_AUTH_ROLE, useAuthUser } from 'auth-module'
import type { CurrentLangProvider } from 'billing-module/src/static'
import { PROVIDERS, useNewAccountBillingStore } from 'billing-module'
import { useBreakpoint, useTouchDevice } from 'platform-module'
import { LineGradientLoadingBar, LoadingOverlay } from 'vue-components'
import { U_GET_INITIAL_ASIDE_STATE } from 'vue-components2'

import Vpc from './vite-plugin-checker.vue'

import { useAppStore, useAuthStore, useDevicesStore } from '@/stores'
import { MAX_BREAKPOINT_OBJ, MODULES_BOOLEANS } from '@/static'

// VARS
const vpcEnabled: boolean = false
const modalConfirmation = ref()
const router = useRouter()
const mode = ref(process.env.NODE_ENV)
// TODO: optimize useBreakpoint initialization
const toggleAsideBreakpoints = {
    [ROLE_NAME.USER]: 1096,
    [ROLE_NAME.ADMIN]: 1670,
    [ROLE_NAME.VPN_SERVER]: 1670,
    [ROLE_NAME.AUTHOR]: 1670,
    [ROLE_NAME.PUBLIC]: 1670
}

// STORES
const authStore = useAuthStore()
const appStore = useAppStore()
const devicesStore = useDevicesStore()
const billingStore = useNewAccountBillingStore()

const { M_SET_APP_STORE_VALUE, M_HANDLE_ACCESS_PAGES } = appStore
const { INIT_SUB_WATCHER, M_SET_DEFAULT_SOURCE } = billingStore

// COMPOSABLES
const { isTouchDevice } = useTouchDevice()
const breakpointComposable = useBreakpoint({
    toggleAsideBreakpoints,
    MAX_BREAKPOINT_OBJ,
    isTouchDevice
})

// WATCHERS
watch(() => authStore.user, (user, oldUser) => {
    if (user) {
        M_HANDLE_ACCESS_PAGES({ user, routeNames: window.routeNames })

        const { INIT_BREAKPOINT_ENUM, SET_ASIDE_TOGGLE_BREAKPOINT } = breakpointComposable
        INIT_BREAKPOINT_ENUM({
            asideOpened: U_GET_INITIAL_ASIDE_STATE({
                breakpoint: breakpointComposable.asideToggleBreakpoint.value
            })
        })

        SET_ASIDE_TOGGLE_BREAKPOINT(U_GET_AUTH_ROLE({ user }).ROLE)
        const isSourceChanged = user && oldUser && oldUser.default_source !== user.default_source
        isSourceChanged && M_SET_DEFAULT_SOURCE({ card_id: user.default_source })
    }
})

// COMPUTED PROPERTIES
const User = computed(() => authStore.user)

setTimeout(() => INIT_SUB_WATCHER({ user: User, router }), 4000)

// SET DATA TO STORES FROM window.configs START
type ConfigKey = keyof Window['configs']

for (const key of Object.keys(window.configs) as ConfigKey[]) {
    M_SET_APP_STORE_VALUE({ key, value: window.configs[key] })
}

devicesStore.state.agent = window.configs.device.agent
devicesStore.state.isDesktop = window.configs.device.isDesktop
devicesStore.state.isMobile = window.configs.device.isMobile
devicesStore.state.isTablet = window.configs.device.isTablet
devicesStore.state.isTablet = window.configs.device.isTablet
devicesStore.state.languages = window.configs.device.languages
devicesStore.state.platform = window.configs.device.platform
devicesStore.state.browser = window.configs.device.browser
// SET DATA TO STORES FROM window.configs END

// PROVIDERS
provide('breakpointComposable', breakpointComposable)
provide('isTouchDevice', isTouchDevice)
provide('authStore', authStore)
provide('appStore', appStore)
provide('User', User)
provide('modulesBooleans', MODULES_BOOLEANS)
provide<string>('projectName', import.meta.env.VITE_PROJECT_NAME)
provide<string>('projectIcon', 'epicvpn-logo')
provide<CurrentLangProvider>(PROVIDERS.LANG, { lang: appStore.locale })
provide('ToastsI18n', computed(() => appStore.translations.toasts))
provide('InputErrorsI18n', computed(() => appStore.translations.input_errors))
provide('CommonI18n', computed(() => appStore.translations.common))
provide('CommonInputsI18n', computed(() => appStore.translations.common_inputs))
provide('userRoleComposable', useAuthUser({
    user: authStore.user
}))

onMounted(() => {
    const instance = getCurrentInstance()

    if (instance) {
        instance.appContext.config.globalProperties.$modalConfirmation = modalConfirmation.value
    }
})
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
